import 'swiper/swiper-bundle.css';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import '../sdist/tailwind.css';
import '@fortawesome/fontawesome-free/css/all.css';
import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse);

window.addEventListener('load', () => {
  // swiper 3 images
  var swiper_3img = new Swiper(".swiper_3img", {
    modules: [Navigation, Pagination],
    loop: true,
    autoHeight: true,
    slidesPerView: 1,
    spaceBetween: 10,
    freeMode: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });



  var swiper_impression = new Swiper(".swiper-impression", {
    modules: [Navigation, Pagination],
    loop: true,
    autoHeight: true,
    slidesPerView: 1,
    spaceBetween: 10,
    freeMode: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });


  window.MainNavigation = function() {
    return {
      mobileOpen: false,
      isSticky: false,
      init() {
        let checkSticky = ([e]) => {
          this.isSticky = e.intersectionRatio < 1;
        };
        this.observer = new IntersectionObserver(checkSticky, {threshold: [1]});
        this.observer.observe(this.$el);
      },
    };
  }

  Alpine.start();

});
